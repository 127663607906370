

import { Cart, openCart } from '@yggdrasil/Cart'
import { CF2Component, registerComponent } from 'javascript/lander/runtime'

export default class CartIcon extends CF2Component {

constructor(el, runtimeSel) {
super(el, runtimeSel)
}


    mount() {
      if (!(globalResourceData.resourceName === 'checkout')) {
        Cart.stores.cartData.listen((cartData) => {
          // NOTE: we need to start icon rendering with 
          this.updateCount(cartData)
        })
        this.updateCount(Cart.stores.cartData.get())
      }
    }
    updateCount(cartData) {
      this.element.querySelector('.elCartIcon').dataset.count = cartData.items.reduce((acc, item) => acc+item.quantity, 0)
    }



}

registerComponent('CartIcon', CartIcon)
window["CartIcon"] = CartIcon

